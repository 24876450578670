import { mathTableCellSerializer } from "@viuch/feature-instrument-settings/serializers/serializeTable";
import { isZeroLengthMathExpr } from "@viuch/math-editor";

import type { TableTextTaskInstrumentSettings } from "../../../entities/task-instruments-settings/BaseTaskInstrumentSettings";
import type {
    TMathTableInstrumentSettings,
    TRawHiddenCellPosition,
} from "@viuch/feature-instrument-settings/service-types/tables";

export function serializeInformationTableTaskSettings(
    model: TableTextTaskInstrumentSettings
): TMathTableInstrumentSettings {
    const { cells, title, renderInFrame, viewSettings, validationStrategy } = model.instrument;

    return {
        instrument_type: "table",
        cells: cells.map((row) => row.map((cell) => cell.accept(mathTableCellSerializer))),
        view_settings: viewSettings.serialize(),
        render_in_frame: renderInFrame,
        validation_strategy: validationStrategy,
        title: isZeroLengthMathExpr(title) ? void 0 : title,
        hidden_cells: cells.reduce<TRawHiddenCellPosition[]>((hiddenCells, cell, i) => {
            cell.forEach((cell, j) => {
                if (cell.isHidden) {
                    hiddenCells.push({ row: i, col: j });
                }
            });

            return hiddenCells;
        }, []),
        settings_uuid: model.settingsUuid,
    };
}
