import type { TFormControl } from "@viuch/form-builder/types/controls";

import { createLabel } from "../controls/label";
import { valueMathControl } from "../controls/value";

const roundingControl: TFormControl = {
    control: "Form",
    layout: "column",
    condition: { "./precision": true },
    controls: [
        {
            path: "./rounding_rule",
            control: "Select",
            label: "Метод округления",
            initialValue: "round",
            data: {
                type: "static-items",
                items: [
                    {
                        value: "round",
                        label: "Ближайшее целое",
                    },
                    {
                        value: "floor",
                        label: "Округление вниз (1.999 -> 1)",
                    },
                    {
                        value: "ceil",
                        label: "Округление вверх (9.001 -> 10)",
                    },
                ],
            },
        },
        {
            path: "./precision",
            control: "Number",
            label: "Точность",
        },
    ],
};

const fractionRounding: TFormControl = {
    control: "Form",
    condition: {
        "./target": "decimal",
    },
    layout: "column",
    controls: [
        {
            path: "./precision",
            control: "Checkbox",
            label: "Округление",
            virtual: true,
        },
        roundingControl,
    ],
};

export const fractionConversion: TFormControl = {
    control: "Form",
    layout: "column",
    condition: { "./solver_type": "fraction_conversion" },
    controls: [
        createLabel("Преобразует дробное число"),
        valueMathControl,
        {
            path: "./target",
            control: "Select",
            label: "Target",
            initialValue: "normal",
            data: {
                type: "static-items",
                items: [
                    {
                        value: "normal",
                        label: "Обыкновенная дробь",
                    },
                    {
                        value: "decimal",
                        label: "Десятичная дробь",
                    },
                    {
                        value: "mixed",
                        label: "Смешанная дробь",
                    },
                ],
            },
        },
        fractionRounding,
    ],
};
