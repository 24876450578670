import {
    AngleIcon,
    ArcCosIcon,
    ArcCtgIcon,
    ArcSinIcon,
    ArcTgIcon,
    BackspaceIcon,
    BelongIcon,
    BottomEnterIcon,
    CapsLockIcon,
    ChangeLangIcon,
    ColumnAdditionIcon,
    ColumnMultiplication,
    ColumnSubtractionIcon,
    ComplexIntegralIcon,
    CosIcon,
    CtgIcon,
    Degree2Icon,
    Degree3Icon,
    DegreeIcon,
    DeltaIcon,
    DerivativeIcon,
    DifferentialIcon,
    DifferentialXIcon,
    DivideIcon,
    DivideNumsIcon,
    EmptyIcon,
    ExponentIcon,
    FactorialIcon,
    FracIcon,
    FuncIcon,
    FuncXIcon,
    HistoryRedoIcon,
    HistoryUndoIcon,
    InfinityIcon,
    InputCurlyBracketIcon,
    InputRoundBracketIcon,
    IntegralIcon,
    IntegralXIcon,
    IntersectionIcon,
    LeftArrowIcon,
    LimIcon,
    LnIcon,
    Log10Icon,
    Log2Icon,
    LogIcon,
    LongDivisionIcon,
    MobileAlgebraIcon,
    MobileGeometryIcon,
    MobileMathIcon,
    ModuleIcon,
    MultiplyIcon,
    NotBelongIcon,
    NotEqualsIcon,
    NumDegreeIcon,
    NumIndexIcon,
    ParallelIcon,
    PercentIcon,
    PerpendicularIcon,
    PiIcon,
    ResultIcon,
    RightArrowIcon,
    ShiftIcon,
    SinIcon,
    SpaceArrowLeftIcon,
    SpaceArrowRightIcon,
    SqrtIcon,
    Sqrt3Icon,
    SqrtWithIndex,
    SubsetIcon,
    TgIcon,
    TopAngleIcon,
    TopEnterIcon,
    UnionSetIcon,
    VectorCoordinatesIcon,
    VectorIcon,
    SquareIcon,
    TrapezeIcon,
    RectIcon,
    CircleIcon,
    ParallelogramIcon,
    RhombIcon,
    TriangleIcon,
    SemicircleIcon,
    CollinearIcon,
    CounterCollinearIcon,
    KeyboardIcon,
} from "../../../icons";

export const keyboardIconsMap = {
    "not-equals": NotEqualsIcon,
    "long-division": LongDivisionIcon,
    "column-addition": ColumnAdditionIcon,
    "column-multiplication": ColumnMultiplication,
    "column-subtraction": ColumnSubtractionIcon,
    "left-arrow": LeftArrowIcon,
    "right-arrow": RightArrowIcon,
    "top-enter": TopEnterIcon,
    "bottom-enter": BottomEnterIcon,
    "differential-x": DifferentialXIcon,
    "integral-x": IntegralXIcon,
    "sqrt-index": SqrtWithIndex,
    "complex-integral": ComplexIntegralIcon,
    "union-set": UnionSetIcon,
    "top-angle": TopAngleIcon,
    "num-index": NumIndexIcon,
    "num-degree": NumDegreeIcon,
    "input-curly-bracket": InputCurlyBracketIcon,
    "input-round-bracket": InputRoundBracketIcon,
    "divide-nums": DivideNumsIcon,
    "change-lang": ChangeLangIcon,
    "space-arrow-left": SpaceArrowLeftIcon,
    "space-arrow-right": SpaceArrowRightIcon,
    "vector-coordinates": VectorCoordinatesIcon,
    caps: CapsLockIcon,
    degree: DegreeIcon,
    degree2: Degree2Icon,
    degree3: Degree3Icon,
    sqrt: SqrtIcon,
    sqrt3: Sqrt3Icon,
    frac: FracIcon,
    derivative: DerivativeIcon,
    module: ModuleIcon,
    differential: DifferentialIcon,
    integral: IntegralIcon,
    lim: LimIcon,
    log: LogIcon,
    log2: Log2Icon,
    log10: Log10Icon,
    func: FuncIcon,
    vector: VectorIcon,
    parallel: ParallelIcon,
    perpendicular: PerpendicularIcon,
    angle: AngleIcon,
    intersection: IntersectionIcon,
    subset: SubsetIcon,
    result: ResultIcon,
    backspace: BackspaceIcon,
    shift: ShiftIcon,
    divide: DivideIcon,
    multiply: MultiplyIcon,
    sin: SinIcon,
    cos: CosIcon,
    tg: TgIcon,
    ctg: CtgIcon,
    arcsin: ArcSinIcon,
    arccos: ArcCosIcon,
    arctg: ArcTgIcon,
    arcctg: ArcCtgIcon,
    belong: BelongIcon,
    notBelong: NotBelongIcon,
    funcX: FuncXIcon,
    factorial: FactorialIcon,
    empty: EmptyIcon,
    delta: DeltaIcon,
    pi: PiIcon,
    infinity: InfinityIcon,
    percent: PercentIcon,
    exponent: ExponentIcon,
    ln: LnIcon,
    algebra: MobileAlgebraIcon,
    geometry: MobileGeometryIcon,
    math: MobileMathIcon,
    historyRedo: HistoryRedoIcon,
    historyUndo: HistoryUndoIcon,
    square: SquareIcon,
    trapeze: TrapezeIcon,
    rect: RectIcon,
    circle: CircleIcon,
    parallelogram: ParallelogramIcon,
    rhomb: RhombIcon,
    triangle: TriangleIcon,
    semicircle: SemicircleIcon,
    collinear: CollinearIcon,
    "counter-collinear": CounterCollinearIcon,
    keyboard: KeyboardIcon,
};
