import { action, makeObservable, observable } from "mobx";

import { deepClone } from "@viuch/shared/utils/data/copy";

import type { BaseTableInstrumentSettingsCell } from "./cells";
import type { TableViewSettings } from "./TableViewSettings";
import type { TTableValidationStrategy } from "@viuch/instrument-table/entities/types";
import type { TSerializedState } from "@viuch/math-editor";

export abstract class BaseTableInstrumentSettingsImpl {
    @observable cells: BaseTableInstrumentSettingsCell[][];
    @observable.ref title: TSerializedState;
    @observable renderInFrame: boolean;
    @observable.ref validationStrategy: TTableValidationStrategy;

    readonly viewSettings: TableViewSettings;

    protected constructor(
        cells: BaseTableInstrumentSettingsCell[][],
        title: TSerializedState,
        renderInFrame: boolean,
        validationStrategy: TTableValidationStrategy,
        viewSettings: TableViewSettings
    ) {
        this.cells = cells ?? [];
        this.title = deepClone(title);
        this.renderInFrame = renderInFrame;
        this.viewSettings = viewSettings;
        this.validationStrategy = validationStrategy;

        makeObservable(this);
    }

    @action.bound
    setTitle(title: TSerializedState): void {
        this.title = title;
    }

    abstract addCol(): void;

    abstract addRow(): void;

    protected get rowLength(): number {
        return this.cells.at(0)?.length ?? 2;
    }

    protected canRemoveRow(i: number): boolean {
        return this.cells.length > 1;
    }

    protected canRemoveColumn(i: number): boolean {
        return this.cells[0].length > 1;
    }

    @action.bound
    removeRow(i: number) {
        if (this.canRemoveRow(i)) {
            this.cells.splice(i, 1);
        }
    }

    @action.bound
    removeColumn(i: number) {
        if (this.canRemoveColumn(i)) {
            this.cells.forEach((row) => {
                row.splice(i, 1);
            });
        }
    }

    @action.bound
    setRenderInFrame(value: boolean) {
        this.renderInFrame = value;
    }

    @action.bound
    setValidationStrategy(value: TTableValidationStrategy) {
        this.validationStrategy = value;
    }
}
