import type { TAnyAction } from "../types";

export type THideAction = {
    type: "hide";
};

export function createHideAction(): THideAction {
    return { type: "hide" };
}

export function isHideAction(action: TAnyAction): action is THideAction {
    return action.type === "hide";
}
