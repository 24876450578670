import { action, makeObservable } from "mobx";

import { createSerializedContainer } from "@viuch/math-editor";

import type { BaseTableInstrumentSettingsCell } from "./cells";
import type { TSerializedState } from "@viuch/math-editor";

import { BaseTableInstrumentSettingsImpl } from "./BaseTableInstrumentSettingsImpl";
import { SelectTableInstrumentSettingsCell, TextTableInstrumentSettingsCell } from "./cells";
import { TableViewSettings } from "./TableViewSettings";
import { generateSelectTableCells } from "./utils";

export class TableSelectInstrumentSettingsImpl extends BaseTableInstrumentSettingsImpl {
    constructor(
        cells: BaseTableInstrumentSettingsCell[][],
        title: TSerializedState,
        renderInFrame: boolean,
        viewSettings: TableViewSettings
    ) {
        super(cells, title, renderInFrame, null, viewSettings);

        makeObservable(this);
    }

    protected override canRemoveRow(i: number): boolean {
        return i > 1 && super.canRemoveRow(i);
    }

    protected override canRemoveColumn(i: number): boolean {
        return i > 1 && super.canRemoveColumn(i);
    }

    clone(): TableSelectInstrumentSettingsImpl {
        return new TableSelectInstrumentSettingsImpl(
            this.cells.map((row) => row.map((col) => col.clone())),
            this.title,
            this.renderInFrame,
            this.viewSettings.clone()
        );
    }

    static createEmpty() {
        return new TableSelectInstrumentSettingsImpl(
            generateSelectTableCells(2, 2),
            createSerializedContainer(),
            false,
            TableViewSettings.createEmpty()
        );
    }

    @action.bound
    addCol() {
        this.cells[0].push(new TextTableInstrumentSettingsCell(false, createSerializedContainer()));

        this.cells.slice(1).forEach((row) => {
            row.push(new SelectTableInstrumentSettingsCell(false, "empty"));
        });
    }

    @action.bound
    addRow() {
        const elements = Array.from({ length: this.rowLength - 1 }).map(() => {
            return new SelectTableInstrumentSettingsCell(false, "empty");
        });

        this.cells.push([
            //
            new TextTableInstrumentSettingsCell(false, createSerializedContainer()),
            ...elements,
        ]);
    }
}
