import type { TButtonTitle, TChangeTabButton } from "./Wrapper.types";
import type { TMathKeyboardButton, TTouchKeyboardLanguage } from "../types";

import { createCursorMoveAction } from "../../../../actions/cursor";
import { createHideAction } from "../../../../actions/hide";
import { createHistoryAction } from "../../../../actions/history";
import { __systemTag } from "../../../../core/keyboard-button-tags/constants";

export const changeTabButtons: TChangeTabButton[] = [
    {
        iconName: "math",
        tab: "maths",
    },
    {
        iconName: "algebra",
        tab: "algebra",
    },
    {
        iconName: "geometry",
        tab: "geometry",
    },
];

export const langMenuToButtonTitle: Record<TTouchKeyboardLanguage, TButtonTitle> = {
    en: "Abc",
    ru: "Абв",
    gr: "Αβγ",
};

export const undoButton: TMathKeyboardButton = {
    actionCreator: () => createHistoryAction("undo"),
    icon: "historyUndo",
    tags: [__systemTag],
};

export const redoButton: TMathKeyboardButton = {
    actionCreator: () => createHistoryAction("redo"),
    icon: "historyRedo",
    tags: [__systemTag],
};

export const cursorLeftButton: TMathKeyboardButton = {
    actionCreator: () => createCursorMoveAction("left"),
    icon: "left-arrow",
    tags: [__systemTag],
};

export const cursorRightButton: TMathKeyboardButton = {
    actionCreator: () => createCursorMoveAction("right"),
    icon: "right-arrow",
    tags: [__systemTag],
};

export const hideButton: TMathKeyboardButton = {
    actionCreator: () => createHideAction(),
    icon: "keyboard",
    tags: [__systemTag],
};
